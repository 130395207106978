import logo from './logo.svg';
import './App.css';
import { Helmet } from "react-helmet"


function App() {
  return (
    <>
{/* <picture className="scarecrow-img3">
        <img
          src="https://res.cloudinary.com/dorrhcigy/image/upload/v1697943747/logo-epi_mtc0hi.png"
          alt="scarecrow"
        />
      </picture> */}
      
  <main>
  
    <div className="main-wrapper">
      <picture className="scarecrow-img">
        <img
          src="https://res.cloudinary.com/dorrhcigy/image/upload/v1697943825/tounsi-mti9er-tunisia_soeqxd.gif"
          alt="scarecrow"
        />
      </picture>
     
      <div className="error-text">
        <h2>Partagi Lien m3a Jma3a !<picture className="scarecrow-img2">
        <img
          src="https://3.bp.blogspot.com/-92YsA_v6KcQ/W2SlR3fotPI/AAAAAAAAqjo/BWr8fO4JhAwK2oAdZRlG_MLdN05hVSRdgCLcBGAs/s1600/Flag_of_Palestine.gif"
          alt="scarecrow"
        />
      </picture></h2>
        <p>
      
    <br/>  You do not have to be Muslim, Arab, or white to support Palestine, you have to be a human being 🇵🇸


        </p>
        <span className="input-group-btn">
        <button className="btn" type="button" onClick={() => window.open('https://link.aymenghnia.dev/game', '_blank')}>
 Lanci E tor7 ! 
</button>  
{/* <picture className="scarecrow-img2">
        <img
          src="https://3.bp.blogspot.com/-92YsA_v6KcQ/W2SlR3fotPI/AAAAAAAAqjo/BWr8fO4JhAwK2oAdZRlG_MLdN05hVSRdgCLcBGAs/s1600/Flag_of_Palestine.gif"
          alt="scarecrow"
        />
      </picture> */}
      <picture className="scarecrow-img2">
        <img
          src="https://res.cloudinary.com/dorrhcigy/image/upload/v1697943747/logo-epi_mtc0hi.png"
          alt="scarecrow"
        />
      </picture>
        </span>
      </div>
    </div>
  </main>
  

   
</>


  );
}

export default App;
